<template>
  <div class="page page-publication-url">
    <PageHeader
    :icon="$t('PUBLICATION_URL.ICON')"
    :title="$t('PUBLICATION_URL.TITLES')"
    back="/settings/"
    >
      <template slot="nav">
        <!-- <small><strong>{{$tc('PUBLICATION_URL.TITLE_COUNT',count)}}</strong></small> -->
      </template>

      <v-tooltip bottom transition="none">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            depressed
            color="success"
            class="ml-2"
            v-bind="attrs"
            v-on="on"
            @click="validate"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        {{ $t('COMMON.ADD') }}
      </v-tooltip>
    </PageHeader>

    <v-dialog v-model="dialogMacro" width="300px" overlay-color="transparent">
      <v-card>
        <div>
          <v-form ref="formMacro" v-model="validMacro" class="pr-4 pl-4">
            <br />
            <p>Create a macro (without brace nor space).</p>
            <v-row class="mt-0 mb-0">
              <v-col cols="12" class="pt-0 pb-0">
                <v-text-field
                  v-model="macro"
                  :label="$t('INPUT.MACRO')"
                  :placeholder="$t('INPUT.MACRO')"
                  :rules="[$rules.required]"
                  required
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
          <div class="text-center pa-4 pt-0">
            <v-btn
              @click="confirmMacro"
              large
              depressed
              rounded
              color=" success custom"
              :disabled="!validMacro"
            >
              {{ $t("COMMON.ADD") }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-row class="ma-0 flex-row flex-grow width-100 height-100">
      <v-form ref="form" class="width-100">
        <v-row class="ma-0">
          <v-col cols="12">
            <v-card flat outlined class="pa-4 mt-4">
              <p>Template</p>
              <v-divider />
              <div>
                <v-chip-group active-class="primary--text" column>
                  <v-chip
                    v-if="!template.includes('{url}')"
                    key="url-chip"
                    @click="template += '{url}'"
                    small
                  >
                    {url}
                  </v-chip>
                  <v-chip v-else key="'url-chip'" class="muted" small>
                    {{ "{url}" }}
                  </v-chip>
                  <template v-for="(macro, i) in macros">
                    <v-chip
                      :key="`chip-${i}`"
                      @click="template += `{${macro.key}\}`"
                      v-if="!template.includes(`{${macro.key}\}`)"
                      small
                    >
                      {{ `{${macro.key}\}` }}
                    </v-chip>
                    <v-chip :key="`chip-${i}`" v-else class="muted" small>
                      {{ `{${macro.key}\}` }}
                    </v-chip>
                  </template>
                </v-chip-group>
              </div>

              <div>
                <v-text-field
                  class="my-input"
                  v-model="template"
                  outlined
                  rounded
                  hide-details
                  :rules="[$rules.required]"
                  required
                />
                <!-- <v-row class="px-5 pt-2 justify-end">
                  <v-btn color="primary">Valider</v-btn>
                </v-row> -->
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="ma-0">
          <v-col class="d-flex justify-space-between">
            <div class="col-8 pa-0 height-100 scroll" style="height: calc(-310px + 100vh);">
              <!-- MACROS -->
              <v-toolbar flat height="68px">
                <p class="pt-2 mr-4">{{ $t("INPUT.MACROS") }}</p>
                <v-btn
                  @click="editMacro"
                  class="mr-3"
                  color="secondary black--text"
                  depressed
                  small
                  rounded
                >
                  <v-icon class="mr-2" size="16">mdi-plus</v-icon>
                  {{ $t("PUBLICATION_URL.ADD_MACRO") }}
                </v-btn>
              </v-toolbar>
              <v-divider />

              <div v-if="macros" class="pb-0 justify-center align-center">
                <v-tabs
                  slider-size="2"
                  slider-color="#E0BC37"
                  class="macro-tabs"
                  height="90px"
                >
                  <template>
                    <v-tab
                      v-for="(macro, indexMacro) in macros"
                      :key="`${indexMacro}-tab`"
                      grow="true"
                      show-arrows
                    >
                      <div>
                        <div class="text-nowrap text-ellipsis text-h6">
                          {{ `{${macro.key}\}` }}
                        </div>
                        <v-chip
                          v-if="macro.configs.length"
                          color="primary"
                          class="mr-2 ml-2"
                          x-small
                        >
                          {{ macro.configs.length }}
                        </v-chip>
                        <div class="macro-delete-corner">
                          <v-btn @click="removeMacro(indexMacro)" icon small>
                            <v-icon size="16">mdi-trash-can-outline</v-icon>
                          </v-btn>

                          <v-dialog
                            v-model="dialogMacros[indexMacro]"
                            overlay-color="transparent"
                            width="300px"
                          >
                            <v-card class="pa-4">
                              <p>Change macro title</p>
                              <v-text-field
                                v-model="macroKeyTmp"
                                outlined
                                dense
                                :rules="[$rules.required]"
                                required
                              />
                              <div>
                                <v-btn
                                  color="success"
                                  rounded
                                  depressed
                                  dark
                                  @click="updateMacro(indexMacro)"
                                >
                                  <span>APPLY</span>
                                </v-btn>
                              </div>
                            </v-card>
                          </v-dialog>

                          <v-btn
                            color="primary"
                            dark
                            icon
                            small
                            @click="showDialogMacro(indexMacro, true)"
                          >
                            <v-icon size="16">mdi-pencil</v-icon>
                          </v-btn>

                          <!-- </v-menu
                          <v-btn
                          @click="removeMacro(indexMacro)"
                          icon
                          small
                          v-bind="attrs"
                          v-on="on"
                          >
                            <v-icon size="16">mdi-pencil</v-icon>
                          </v-btn> -->
                        </div>
                      </div>

                      <!-- <v-col>
                        <v-row class="pb-2" justify="center">
                        </v-row>
                        <v-row justify="space-around">
                          <div>
                            <v-chip
                              v-if="macro.configs.length"
                              class="mr-2 px-5"
                              color="primary"
                              small
                            >

                            </v-chip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">

                              </template>
                              <span>{{ $t("PUBLICATION_URL.DELETE_MACRO") }}</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                      </v-col> -->
                    </v-tab>
                  </template>
                  <template>
                    <v-tab-item
                      v-for="(macro, indexMacro) in macros"
                      :key="indexMacro"
                      :transition="false"
                      :reverse-transition="false"
                    >
                      <v-btn
                        color="secondary black--text"
                        class="ma-4"
                        @click="() => addConfig(indexMacro, macro)"
                        small
                        depressed
                        rounded
                      >
                        <v-icon class="mr-2" size="16">mdi-plus</v-icon>
                        <span>{{ $t("PUBLICATION_URL.ADD_CONFIG") }}</span>
                      </v-btn>

                      <v-divider />
                      <template v-for="(config, indexConfig) in macro.configs">
                        <div :key="indexConfig">
                          <v-divider />
                          <v-row class="ma-0">

                          </v-row>

                          <v-row class="ma-0">
                            <v-col cols="2" class="pr-0 pb-0">
                              <v-select
                                class="my-input"
                                :label="$t('INPUT.MACRO_TYPE')"
                                item-text="text"
                                item-value="value"
                                :items="[
                                  {
                                    text: $t('INPUT.MACRO_TYPE_REGEX'),
                                    value: 'regex',
                                  },
                                  {
                                    text: $t('INPUT.MACRO_TYPE_DATA'),
                                    value: 'data',
                                  },
                                  {
                                    text: $t('CHANNEL.TITLE'),
                                    value: 'channel',
                                  }

                                ]"
                                v-model="config.type"
                                dense
                                hide-details
                                outlined
                                :rules="[$rules.required]"
                                required
                              >
                              <template slot="prepend">
                                <strong class="grey--text mr-1 mt-1">if</strong>
                              </template>
                              </v-select>
                            </v-col>

                            <v-col cols="3" class="pr-0" v-if="config.type !== 'channel'">
                              <v-text-field
                                class="my-input"
                                v-model="config.on"
                                :rules="[$rules.required]"
                                outlined
                                dense
                                hide-details
                              >
                              </v-text-field>
                            </v-col>

                            <v-col cols="6" v-if="config.type !== 'channel'">
                              <v-text-field
                                class="my-input"
                                v-model="config.trigger"
                                :rules="[$rules.required]"
                                auto-grow
                                prepend-icon="mdi-equal"
                                dense
                                outlined
                                hide-details
                              />

                            </v-col>
                            <v-col cols="9" v-if="config.type === 'channel' || config.trigger === 'channel.controller_name'">
                              <v-autocomplete
                                :items="channels"
                                item-text="controller_name"
                                item-value="controller_name"
                                class="my-input"
                                v-model="config.trigger"
                                :rules="[$rules.required]"
                                prepend-icon="mdi-equal"
                                outlined
                                dense
                                hide-details
                                >
                              </v-autocomplete>
                            </v-col>

                            <v-col cols="1" class="text-right">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    @click="removeConfig(indexMacro, indexConfig)"
                                    icon
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("PUBLICATION_URL.REMOVE_CONFIG") }}</span>
                              </v-tooltip>
                            </v-col>

                            <v-col cols="11" class="pt-0 pl-10">
                              <v-text-field
                                class="my-input"
                                v-model="config.value"
                                :rules="[$rules.required]"
                                auto-grow
                                dense
                                outlined
                                hide-details
                              >
                                <template slot="prepend">
                                  <span class="grey--text mr-1 mt-1 text-nowrap">redirect to</span>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </div>
                      </template>
                    </v-tab-item>
                  </template>
                </v-tabs>
              </div>
            </div>

            <div class="col-4 white publication-url-list pl-4 pr-0"  style="height: calc(-211px + 100vh);">
              <!-- SAMPLES -->
              <v-toolbar color="transparent" flat dense class="mb-2">
                <v-text-field
                v-model="job_search"
                @input="loadUrlTest"
                :placeholder="$t('COMMON.SEARCH') + ': ' + $t('JOB.TITLE')"
                clearable
                outlined
                dense
                prepend-icon="mdi-magnify"
                hide-details
                />
              </v-toolbar>
              <v-divider />
              <v-row class="ml-0 mt-0 b-mb-0 flex-row width-100 height-100 position-relative">
                <v-col cols="12" class="flex-grow scroll pa-0 height-100">
                  <Loading :value="loading" />
                  <div class="scroll pb-10" style="max-height:calc(100% - 150px);">
                    <div v-for="(job, i) in publicationItems" :key="job.id">
                      <div class="ma-4">
                        <small class="grey--text">{{$t('COMMON.SAMPLE')}}</small>
                        <h4 class="mt-0">{{job.title}}</h4>
                      </div>
                      <v-divider />
                      <v-list class="pa-0 ma-0" color="transparent" dense>
                        <template v-for="(publication, j) in job.publications || []">
                          <v-list-item :class="['transparent', publication.channel_subscription.status !== 1 ? 'muted' : '']" :key="'pub' + i + j">
                            <v-list-item-content>
                              <v-list-item-title class="primary--text" :title="Object.keys(publication.channel).map(k => k + ': ' + JSON.stringify(publication.channel[k])).join('\n')"><strong>{{ publication.channel.title }}</strong></v-list-item-title>
                              <v-list-item-subtitle >
                                <v-icon size="16" color="info" class="mr-2">mdi-arrow-left-thick</v-icon>
                                <a @click="redirect(job.url)" :title="'Origin:\n' + job.url.split('?').join('\n?').split('&').join('\n&').split('#').join('\n#')"><small>{{ job.url }}</small></a>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle v-if="publication.job.url !== publication.url">
                                <v-icon size="14" color="green" class="mr-2">mdi-arrow-right-thick</v-icon>
                                <a @click="redirect(publication.url)" :title="'Redirection:\n' + publication.url.split('?').join('\n?').split('&').join('\n&').split('#').join('\n#')"><small>{{ publication.url }}</small></a>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle v-else><v-icon size="14" color="warning" class="mr-2">mdi-help-circle</v-icon><small>No redirection</small></v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider :key="'puba' + i + j" />
                        </template>
                      </v-list>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import PageHeader from '@/components/PageHeader'
import Loading from '@/components/Loading'

export default {
  name: "PageSettingsAllPublicationUrl",
  components: {
    PageHeader,
    Loading
  },
  data: (_t) => ({
    dialogMacros: [],
    numberOfMacros: 0,
    macros: [],
    items: [],
    loading: false,
    publicationItems: [],
    job_search: '',
    channel_subscriptions: undefined,
    tab_channel:0,
    channels: [],

    // form macro
    validMacro: false,
    dialogMacro: false,
    macro: undefined,
    status: undefined,

    // form macro item
    validMacroItem: false,
    dialogMacroItem: false,
    macroItem: undefined,
    macroItemToReplace: undefined,
    macroKeyTmp: undefined,
    template: "{url}",
  }),

  mounted() {
    this.loadPublicationUrl();
    this.loadUrlTest();
    this.loadChannels();
  },

  watch: {
    template() {
      this.loadUrlTest();
    },
    macros() {
      this.loadUrlTest();
    },
  },

  methods: {
    updateMacro(i) {
      const old = this.macros[i].key;
      this.template = this.template.replace(
        new RegExp(`{${old}}`, "gm"),
        `{${this.macroKeyTmp}}`
      );
      this.macros[i].key = this.macroKeyTmp;
      this.showDialogMacro(i, false);
      this.macroKeyTmp = undefined;
    },

    showDialogMacro(i, v = true) {
      const states = [...this.dialogMacros];
      if (v) {
        states[i] = v;
        this.macroKeyTmp = this.macros[i].key;
        this.dialogMacros = states;
      } else {
        this.dialogMacros = [];
      }
    },

    loadChannels () {
      this.$services.api_programmatic.channel.search()
      .then(res => this.channels = res.data)
    },

    redirect (url) {
      window.open(url, '_blank');
    },

    getImage(item, size = 'original') {
      const url =
        `${process.env.VUE_APP_ASSETS}/channels/`
      return `${url + item.channel.controller_name.toLowerCase()}.png`
    },

    genNewUrl (publication) {

      // format url with a template
      let redirect_url = publication.job.url
      const template = this.$clone(this.template)
      const macros = this.formatMacros(this.$clone(this.macros))
      // console.log(template, macros)
      if (template && Object.keys(macros).length > 0) {
        let redirect_error = false

        // replace each macro by values
        const replace = (key) => {
          // console.log(key)
          key = key.slice(1, -1)
          if (key[0] === '$') {
            let urlEscape = false
            if (key[1] === '$') {
              urlEscape = true
              key = key.slice(2)
            } else {
              key = key.slice(1)
            }
            const value = this.$nestedData(key, publication, null, true)
            return typeof value !== 'undefined' ? (urlEscape ? encodeURIComponent(value) : value) : ''
          } else if (key === 'url') {
            return publication.job.url
          } else if (macros[key]) {
            const macro = macros[key]
            // console.log('macro', macro)
            for (let i = 0, len = macro.length; i < len; i++) {
              const m = macro[i]
              if (m.type === 'data') {
                // We allow replacement with empty strings
                if (m.on && m.trigger && (m.value || m.value === '')) {
                  // console.log(m, publication)
                  // get value to check
                  const checkValue = this.$nestedData(m.on, publication, null, true)
                  // if values match
                  if (checkValue === m.trigger) {
                    // console.log('replace', key, '=', m.value)
                    return m.value
                  }
                }
              } else if (m.type === 'regex') {
                if (m.on && m.trigger && m.value) {
                  const checkValue = this.$nestedData(m.on, publication, null, true)
                  const regex = new RegExp(m.trigger, 'gmi')
                  if (regex.test(checkValue)) {
                    return m.value
                  }
                }
              }
            }
          }
          redirect_error = true
          return ''
        }
        /* eslint-disable */
        const templateRegex = /{(\${0,2}[\.\w-]+)}/gim
        const url = template.replace(templateRegex, replace)
        if (!redirect_error) redirect_url = url
      }
      return redirect_url
    },

    loadChannelSubscriptionItems () {
      return this.channel_subscriptions ? Promise.resolve(this.channel_subscriptions) : this.$services.api_programmatic.channel_subscription.search({where:{status:1, channel: true}}).then(r => (r.data ? this.channel_subscriptions = r : r))
    },

    loadChannelJobItems () {
      return this.$services.api_programmatic.job.search({where:{status:1, title: this.job_search || undefined}, options: {limit: 10}})
    },

    loadUrlTest () {
      let c = 0
      this.loading = true
      const fakePublications = []
      // load active medias list
      return Promise.all([
        this.loadChannelSubscriptionItems(),
        this.loadChannelJobItems()
      ])
        // .then(r => {
        //   console.log('---')
        //   console.log(r)
        //   return r ? r : [[],[]]
        // })
        .then(([channel_subscriptions, jobs]) => {
          const results = []
          if (jobs) (jobs.data || []).forEach(job => {
            const publications = []
            if (channel_subscriptions) (channel_subscriptions.data || []).forEach(channel_subscription => {
              const publication = {
                id:'FAKE_'+ (++c),
                channel_id: channel_subscription.channel.id,
                channel: channel_subscription.channel,
                channel_subscription_id: channel_subscription.id,
                channel_subscription,
                job_id: job.id,
                job,
              }
              publication.url = this.genNewUrl(publication)
              publications.push(publication)
            })
            job.publications = publications
            results.push(job)
          })
          this.publicationItems = results
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = false
        })

    },




    // loadUrlTestOLD() {
    //   this.loading = true;
    //
    //
    //
    //   const createUrls = (publications) => {
    //     //
    //     for (let i = 0, len = publications.length; i < len; i++) {
    //       const publication = publications[i]
    //       let redirect_url = null
    //
    //       if (publication && publication.job && publication.job.url) {
    //         redirect_url = publication.job.url
    //         const template = this.template
    //         const macros = {}
    //         this.macros.forEach((item, i) => {
    //           macros[item.key] = item
    //         })
    //         // format url with a template
    //         let redirect_error = false
    //         if (template && Object.keys(macros).length > 0) {
    //           // replace each macro by values
    //           const replace = (key) => {
    //             key = key.slice(1, -1)
    //             if (key[0] === "$") {
    //               key = key.slice(1)
    //               const value = this.$nestedData(key, publication, null, true)
    //               return typeof value !== "undefined" ? value : ""
    //             } else if (key === "url") {
    //               return publication.job.url
    //             } else if (macros[key]) {
    //               const macro = macros[key].configs
    //               for (let i = 0, len = macro.length; i < len; i++) {
    //                 const m = macro[i]
    //                 if (m.type === "data") {
    //                   if (m.on && m.trigger && m.value) {
    //                     // get value to check
    //                     const checkValue = this.$nestedData(
    //                       m.on,
    //                       publication,
    //                       null,
    //                       true
    //                     )
    //                     // if values match
    //                     if (checkValue === m.trigger) {
    //                       return m.value
    //                     }
    //                   }
    //                 } else if (m.type === "regex") {
    //                   if (m.on && m.trigger && m.value) {
    //                     // get value to check
    //                     const checkValue = this.$nestedData(
    //                       m.on,
    //                       publication,
    //                       null,
    //                       true
    //                     )
    //                     const regex = new RegExp(m.trigger, "gmi")
    //                     // if regex match
    //                     if (regex.test(checkValue)) {
    //                       return m.value
    //                     }
    //                   }
    //                 }
    //               }
    //             }
    //             redirect_error = true
    //             return ""
    //           }
    //
    //           const templateRegex = /{(\$?[.\w-]+)}/gim
    //           const url = template.replace(templateRegex, replace)
    //           publications[i].url = redirect_error ? "error" : url
    //         }
    //       }
    //     }
    //     return publications
    //   }
    //
    //   // load 5 offers
    //   this.$services.api_programmatic.publication
    //     .search({
    //       where: { status: 1, channel: true, job: true, },
    //       options: { limit: 5 },
    //     })
    //     .then((response) => {
    //       if (response.data) {
    //         this.publicationItems = createUrls(response.data)
    //       }
    //     })
    //     .finally(() => {
    //             this.loading = false;
    //     })
    // },

    formatMacros(macros) {
      let formattedMacros = {};
      formattedMacros.url = true;

      macros.forEach((macro) => {
        formattedMacros[macro.key] = macro.configs.map((config) => {
          return config.type === 'channel' ? {
            ...config,
            type: 'data',
            on: 'channel.controller_name',
          }
          : config;
        });
      });

      return formattedMacros;
    },

    editMacro(key) {
      key = typeof key === "string" ? key : "";
      this.macro = key || null;
      this.dialogMacro = true;
    },

    loadPublicationUrl() {
      this.loading = true;
      return this.$services.api_programmatic.publication_url
        .search()
        .then((response) => {
          if (response.data) {
            this.items = response.data;
            this.assignItems(response.data);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    assignItems(items) {
      if (items.length === 1) {
        const data = items[0];

        if (typeof data.macros !== "undefined") {
          for (const [key, value] of Object.entries(data.macros)) {
            if (key !== "url") {
              this.macros.push({
                id: this.numberOfMacros,
                key,
                type: value[0] ? value[0].type : undefined,
                on: value[0] ? value[0].on : undefined,
                configs: value.map(config => config.type === 'data' && config.on === 'channel.controller_name' ? {...config, type: 'channel'} : config),
              });
              this.numberOfMacros++;
            }
          }
        }
        if (typeof data.template !== "undefined") this.template = data.template;
        if (typeof data.id !== "undefined") this.id = data.id;
        if (typeof data.status !== "undefined") this.status = data.status;
      }
    },

    addConfig(index) {
      let oldMacro = this.macros[index];
      const newConfig = {
        type: oldMacro.type,
        on: oldMacro.on,
        trigger: "",
        value: "",
        default_value: "",
      }
      oldMacro["configs"].push(newConfig);
      Vue.set(this.macros, [index], oldMacro);
    },

    removeConfig(indexMacro, indexConfig) {
      let oldMacro = this.macros[indexMacro];
      oldMacro["configs"].splice(indexConfig, 1);

      Vue.set(this.macros, [indexMacro], oldMacro);
    },

    removeMacro(indexMacro) {
      Vue.delete(this.macros, indexMacro);
    },

    validate() {
      if (this.$refs.form.validate()) {
        if (this.items.length > 0) return this.updateItem();
        return this.createPublicationUrl();
      }
    },

    createPublicationUrl() {
      const values = {
        status: this.status,
        template: this.template,
        macros: this.formatMacros(this.macros),
      };

      const query = {
        values,
      };

      this.$services.api_programmatic.publication_url
        .create(query)
        .catch((response) => {
          this.$store.dispatch("messenger/add", {
            type: "error",
            code: response.code,
            message: response.message,
            time: 5000,
          });
        });
    },

    updateItem() {
      // prepare values
      const oldValue = this.items[0] || {};
      const values = {};
      if (
        typeof this.template !== "undefined" &&
        this.template !== oldValue.template
      )
        values.template = this.template;
      if (typeof this.status !== "undefined" && this.status !== oldValue.status)
        values.status = this.status;
      if (typeof this.macros !== "undefined")
        values.macros = this.formatMacros(this.macros);

      const query = {
        where: { id: this.items.map((v) => v.id) },
        values,
      };
      this.loading = true;
      this.$services.api_programmatic.publication_url
        .update(query)
        .catch((response) => {
          this.$store.dispatch("messenger/add", {
            type: "error",
            code: response.code,
            message: response.message,
            time: 5000,
          });
        });
    },

    confirmMacro() {
      if (this.$refs.formMacro.validate()) {
        const newObject = {
          id: this.numberOfMacros,
          key: this.macro,
          on: undefined,
          type: undefined,
          configs: [],
        };
        this.macros.push(newObject);
        this.dialogMacro = false;
        this.numberOfMacros++;
      }
    },
  },
};
</script>

<style>
.macro-delete-corner {
  position: absolute;
  top: 0;
  right: 0;
}

.v-tab {
  text-transform: none !important;
}

.icon-list {
  padding: 7px;
  background-color: #f5f5f5;
  border-radius: 30px;
}

.my-input.v-input .v-input__slot {
  border-radius: 7px;
}

.macro-select.v-select {
  background-color: #eee;
}

.macro-name {
  max-width: 60%;
  border-radius: 7px;
}

.macro-line {
  border-bottom: 1px solid #ccc;
}

.macro-tabs .v-tab {
  min-width: 180px;
  min-height: 80px;
  border-bottom: 1px solid #ccc;
}

.macro-config-grid {
  border-left: 1px solid #ccc;
}

.force-wrapped {
  word-break: break-word !important;
}
.publication-url-list a:hover {
  text-decoration: underline;
}
</style>
